@font-face {
    font-family: 'mainFont';
    src: url('./assets/fonts/LydianCursiveMT.ttf');
}

:root {
    --accent-color: #820201;
    --accent-color2: #ee8829;
    --main-font: 'mainFont';
}

.z-1 {
    z-index: -1;
}

a {
    transition: .4s;
}

.contact-info a:hover {
    color: var(--accent-color2) !important;
}

.main-font {
    font-family: var(--main-font);
}

.btn-style-one .btn-title {
    padding: 12px 30px;
}

.footer-widget .text a {
    color: var(--accent-color) !important;
    transition: .4s;
}

.footer-widget .text a:hover {
    color: var(--accent-color2) !important;
}

.header-top-two,
.header-top-two .inner {
    background-color: var(--accent-color)
}

.header-top-two .contact-info li a,
.header-top-two .text,
.header-top-two .top-right .social-links li a {
    color: #fff;
}

.feature-block-one .icon,
.mobile-menu-visible .mobile-menu .menu-backdrop,
.instagram-widget .wrapper-box .image .overlay-link,
.news-block-one .category,
.contact-form i,
.testimonial-block .quote,
.single-product-item .price,
.single-product-item .rating span,
.team-block-one .inner-box .content .designation,
.default-video-box a:before,
.gallery-block-one .caption-title .view-project a,
.gallery-block-one .caption-title h5,
.gallery-block-one .caption-title h5:before,
.filters li.active,
.services-section-five .icon,
.services-section .author-box .designation,
.services-section .left-column,
.about-section .image-wrapper .image-two .caption,
.sec-title.text-center .sub-title:after,
.sec-title .sub-title:before,
.team-block-one .social-links li a:before,
.news-block-two .related-post .post-meta li a,
.news-block-two .related-post .post-meta li a i,
.progress-box .bar-inner,
.progress-box .count-text,
.sec-title .sub-title,
.career-block .time,
.preloader-close,
.scroll-to-top,
.sidebar-title:before,
.loan-calculator .calculate-btn button.active,
.project-post-pagination .page-view,
.portfolio-details .single-project-info .social-links li a:before,
.sidebar-title:after,
.blog-single-post blockquote h5,
.sidebar .popular-tags li a:before,
.sidebar .search-box .form-group button,
.social-links-widget .social-links li a:before,
.page-pagination li.active a,
.page-pagination li a:hover,
.news-block-two .category,
.news-block-three .inner-box:before,
.services-section-five .active .icon-box,
.feature-section-two .owl-theme .owl-nav .owl-next:hover:before,
.feature-section-two .owl-theme .owl-nav .owl-prev:hover:before,
.loan-calculator .calculation-value h4,
.counter-section,
.error-section .inner-section .text,
.banner-carousel .slide-item:before,
.testimonial-block-two .quote,
.service-block-one .icon,
.author-info .designation,
.cta-section .wrapper-box:before,
.testimonial-section-two .owl-theme .owl-nav .owl-next:hover:before,
.testimonial-section-two .owl-theme .owl-nav .owl-prev:hover:before,
.welcome-block-one:nth-child(2) .inner-box:before,
.contact-section-two .icon-box .icon,
.main-footer .footer-bottom:before,
.welcome-block-one .inner-box .icon-box,
.insurance-calculate .wrapper-box,
.btn-style-one .btn-title,
.accordion-box .block .acc-btn.active,
.accordion-box .block .icon-outer .icon_minus,
.about-section-two .list-box li i,
.btn-style-two .btn-title:before,
.contact-section .icon-box .icon,
.main-footer .social-links li a:before,
.main-footer .logo-widget .widget-content:before,
.main-header .header-upper .inner-container,
.bootstrap-select .dropdown-menu li a.active,
.main-header.header-style-two .header-upper .logo-box .logo,
.shop-sidebar-wrapper .price-ranger .ranger-min-max-block input[type="submit"],
.service-block-one .icon .round-shape:before,
.testimonial-section.style-two:before,
.case-block-one .overlay,
.error-search-form .form-group button,
.blog-single-post .tag-list li a:before,
.blog-post-pagination .page-view span,
.blog-post-pagination h5,
.overlay-style-one:before,
.news-block-four .category,
.comments-area .date,
.single-shop-area .price span,
.categories-widget-two ul li.current a,
.contact-form-widget,
.services-details blockquote:before,
.services-details blockquote h4:before,
.cart-area .cart-table tbody .available-info .icon,
.checkout-area .exisitng-customer h5 a,
.single-shop-area .top-content .review,
.sidebar-wrapper .price-ranger .ui-slider-handle,
.sidebar-wrapper .price-ranger .ui-slider .ui-slider-range,
.sidebar-wrapper .price-ranger .ui-slider-handle:before,
.history-section .tab-btn-style-one .nav-item .nav-link.active,
.product-tab-box .tab-btns a.active span,
.product-tab-box .tab-btns .tab-btn:hover span,
.related-product .owl-theme .owl-nav .owl-prev:hover:before,
.related-product .owl-theme .owl-nav .owl-next:hover:before,
.review-box-holder .single-review-box .text-holder .top .rating i,
.case-tabs .case-tab-btns .case-tab-btn:before,
.checkout-area .coupon h5 a,
.services-details blockquote h4,
.checkout-area .coupon:before,
.news-block-two .author-box-two h5,
.checkout-area .exisitng-customer:before,
.cases-section .owl-theme .owl-nav .owl-next:hover:before,
.cases-section .owl-theme .owl-nav .owl-prev:hover:before,
.testimonial-section .owl-theme .owl-nav .owl-next:hover:before,
.testimonial-section .owl-theme .owl-nav .owl-prev:hover:before,
.tab-btn-style-one .nav-item .nav-link:before,
.tab-btn-style-one .nav-item .nav-link.active,
.service-block-one .icon .round-shape:after,
.sticky-header .main-menu .navigation>li.current-menu-item>a {
    background-image: none;
    background-color: var(--accent-color);
}

.selectpicker.d-block {
    display: block !important;
}

.logo-box .logo {
    width: 190px;
    padding: 8px 0;
}

.main-header .header-upper .logo-box .logo {
    padding: 0 !important;
    border-radius: 0px !important;
}

.navigation li a {
    text-transform: uppercase !important;
}

.fixed-header .sticky-header .logo img {
    width: 130px;
}

.fixed-header .sticky-header .logo {
    padding: 5px 0px;
}

.main-header.header-style-two .header-upper .logo-box .logo {
    border-radius: 0px;
    padding: 0;
    margin-bottom: 0;
    margin-right: 50px;
}

.slider-wrapper h3 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.slider-wrapper h4 {
    margin-bottom: 20px;
}

.sec-title h2 {
    position: relative;
    display: block;
    font-size: 3rem;
    line-height: 1.1em;
    font-weight: 700;
    text-transform: capitalize;
    letter-spacing: 0px;
    color: var(--accent-color);
}

.about-section-two .sec-title .text {
    border-bottom: none;
    padding-bottom: 30px;
}

.about-section ul li {
    list-style: disc !important;
    margin-left: 30px;
}

.fw-bold {
    font-weight: bold;
}

.text-justify {
    text-align: justify;
}

.accent-color {
    color: var(--accent-color2);
}

.contact-button {
    color: #fff !important;
}

.contact-button:hover .btn-title:before {
    background-color: var(--accent-color2) !important;
}

.backtotop .icon {
    background-image: linear-gradient(90deg, rgb(130 2 1) 0%, rgb(130 2 1) 100%);
}

.sticky-header .main-menu .navigation>li>a.contact-button {
    padding: 7px 0 !important;
}

.main-header.header-style-two .main-menu .navigation>li>a {
    color: var(--accent-color);
    font-size: 16px;
    font-weight: 900;
}

.main-header.header-style-two .main-menu .navigation>li>a:hover {
    color: var(--accent-color2);
}

section#gallery {
    background-color: rgb(255 182 98 / 13%);
}

.footer.contact-button input {
    color: #fff !important;
}

.footer.contact-button.btn-style-one:hover input {
    background-color: var(--accent-color2) !important;
}

.copyright a:hover {
    color: var(--accent-color) !important;
}

.logo-box .logo {
    background-color: transparent !important;
    float: left !important;
    padding: 10px 0 !important;
}

.logo-box .logo img {
    float: left !important;
}

@media only screen and (max-width: 575px) {
    .main-header.header-style-two .header-upper .logo-box .logo img {
        width: 135px;
    }
}

@media only screen and (max-width: 575px) {
    .main-header.header-style-two .nav-outer .mobile-nav-toggler {
        top: 20px;
    }
}

@media (max-width: 991px) {
    .slider-wrapper {
        height: 80vh;
    }
}